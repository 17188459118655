<template>
    <v-container fluid>
        <v-dialog
            v-model="dialog"
            max-width="500px"
            :fullscreen="$vuetify.breakpoint.xsOnly"
            persistent>
            <invalidate-password-form
                :value="userId"
                @cancel="dialog = false"
                @save="onInvalidation" />
        </v-dialog>
        <v-row>
            <v-col>
                <table-search :search.sync="search" />
            </v-col>
            <v-col>
                <table-buttons
                    :add-dialog.sync="addDialog"
                    :no-data-dialog.sync="noDataDialog"
                    :form-title="tableSchema.formTitle"
                    :claim-name="tableSchema.claimName"
                    :is-add-disabled="isAddDisabled"
                    @download="download()"
                    @addItem="addItem()">
                    <template v-slot:form="">
                        <component
                            :is="form"
                            v-if="!isEmpty(form)"
                            :key="formKey"
                            v-model="editedItem"
                            :schema="getSchema(tableSchema.claimName)"
                            :filter="tableFilter"
                            @cancel="addDialog = false"
                            @open="addDialog = true"
                            @input="onInput()"
                            @delete="onDelete" />
                        <app-form
                            v-else
                            :key="formKey"
                            v-model="editedItem"
                            :schema="getSchema(tableSchema.claimName)"
                            :filter="tableFilter"
                            @cancel="addDialog = false"
                            @open="addDialog = true"
                            @input="onInput()"
                            @delete="onDelete" />
                    </template>
                </table-buttons>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-btn-toggle
                    mandatory
                    class="ml-2 float-right"
                    tile
                    color="deep-purple accent-3"
                    group>
                    <v-btn @click="toggleTabs(false)">
                        Active
                    </v-btn>
                    <v-btn @click="toggleTabs(true)">
                        Inactive
                    </v-btn>
                </v-btn-toggle>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <table-layout
                    :get-slot-name="getSlotName"
                    :table-height="getTableHeight"
                    :items-per-page="getItemsPerPage"
                    :custom-sort="customSort"
                    :headers="filteredHeaders"
                    :items="computedItems"
                    :footer-props="footerProps"
                    :options.sync="options"
                    :item-total="itemTotal"
                    :claim-name="tableSchema.claimName"
                    :show-expand="showExpand"
                    :has-edit-page="hasEditPage"
                    :is-loading="isLoading"
                    @goToEditPage="goToEditPage()">
                    <template v-slot:tableCell="{ header, item }">
                        <table-cell
                            :header="header"
                            :item="item"
                            :download-file="downloadFile"
                            :go-to-table="goToTable"
                            :api-data="apiData"
                            :filter="tableFilter" />
                    </template>
                    <template v-slot:tableActions="{ item }">
                        <table-cell-actions
                            :item="item"
                            :claim-name="tableSchema.claimName"
                            :is-edit-disabled="isEditDisabled"
                            :has-edit-page="hasEditPage"
                            @editItem="editItem"
                            @goToEditPage="goToEditPage">
                            <template v-slot:cellButtons="{}">
                                <template v-if="canEdit('LabTesters')">
                                    <v-btn @click="resendEmail(item.id)">
                                        <v-icon>mdi-email</v-icon>
                                    </v-btn>
                                    <v-btn
                                        v-if="!displayInactive"
                                        @click="invalidatePassword(item.id)">
                                        <v-icon>mdi-key-remove</v-icon>
                                    </v-btn>
                                </template>
                            </template>
                        </table-cell-actions>
                    </template>
                </table-layout>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import tableMixin from "@/mixins/tableMixin";
import axios from "axios"

export default {
    components: {
        tableLayout: () => import("@/components/TableLayout"),
        tableCell: () => import("@/components/TableCell"),
        tableCellActions: () => import("@/components/TableCellActions"),
        tableButtons: () => import("@/components/TableButtons"),
        tableSearch: () => import("@/components/TableSearch"),
        appForm: () => import("@/components/AppForm"),
        invalidatePasswordForm: () => import('@/forms/InvalidatePasswordForm')
    },
    mixins: [tableMixin],
    data() {
        return {
            userId: null,
            dialog: false,
            displayInactive: false
        }
    },
    methods: {
        async resendEmail(id) {
            await axios.get("/api/LabTesters/ResendEmail/" + id);
            alert("User has been resent an invitation email");
        },
        async invalidatePassword(id) {
            this.userId = (await axios.get("/api/LabTesters/GetUserId/" + id)).data;
            this.dialog = true;
        },
        onInvalidation() {
            this.dialog = false;
            this.load();
        },
        async toggleTabs(displayInactive) {
            this.displayInactive = displayInactive;
            this.load();
        },
        setItems(items) {
            this.items = items.filter(i => this.displayInactive ? i.isInactive : !i.isInactive);
        }
    },
};
</script>
